import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Button from 'components/Button';
import { textPropType } from 'shared/propTypes';
import styles from './labelCard.module.scss';

const LabelCard = ({
  selected,
  label,
  onClick,
  containerStyle = '',
  disabled = false,
}) => (
  <div
    className={`${containerStyle} ${styles.labelCard} ${
      selected ? styles.selected : ''
    }`}
  >
    <Button
      handleClick={() => _.isFunction(onClick) && onClick()}
      disabled={disabled}
    >
      {label}
    </Button>
  </div>
);

LabelCard.propTypes = {
  selected: PropTypes.bool,
  label: textPropType.isRequired,
  onClick: PropTypes.func,
  containerStyle: PropTypes.string,
};

export default LabelCard;
